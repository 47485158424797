import React from 'react';
import Helmet from 'react-helmet';
import styles from './experience.module.scss';

export default () => (
  <div className={styles['wrapper']}>
    <Helmet>
      <html lang="en" />
      <title>FREElance Full-Stack Web Developer · Ronny Glotzbach </title>
      <meta
        property="og:site_name"
        content="Full-Stack Web · Ronny Glotzbach"
      />
    </Helmet>

    <div className={styles['experience']}>
      <div className={styles['experience__inner']}>
        {/* // Header */}

        <div className="row">
          <div className="col-lg-2 mb-3">
            <img
              src={'/profile_photo.png'}
              className={styles['experience__photo']}
              alt={'profile_photo'}
            />
          </div>
          <div className="col-lg">
            <h2 className={styles['experience__h2']}>Ronny Glotzbach</h2>
            <h6 className={styles['experience__h6']}>
              FREElance Full-Stack Web Developer
            </h6>
            <div className={styles['experience__bullet_points']}>
              <div> &nbsp; → &nbsp; 35 years old</div>
              <div> &nbsp; → &nbsp; 10+ years of prof. experience</div>
              <div>
                {' '}
                &nbsp; → &nbsp; Based in Germany 🇩🇪 · Philippines 🇵🇭 · Remote
                🌍🌎🌏
              </div>
              <div>
                {' '}
                &nbsp; → &nbsp;{' '}
                <a
                  href="mailto:ronny.glotzbach@gmail.com?Subject=Hello%20Ronny"
                  target="_top"
                >
                  Mail
                </a>{' '}
                ·{' '}
                <a
                  href="https://stackoverflow.com/users/5327882/ronatory"
                  target="_blank"
                >
                  Stackoverflow
                </a>{' '}
                ·{' '}
                <a href="https://github.com/ronatory" target="_blank">
                  Github
                </a>{' '}
                ·{' '}
                <a
                  href="https://www.linkedin.com/in/ronny-glotzbach"
                  target="_blank"
                >
                  LinkedIn
                </a>{' '}
                ·{' '}
                <a
                  href="https://www.xing.com/profile/Ronny_Glotzbach"
                  target="_blank"
                >
                  XING
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* // Expertise */}

        <div className="row">
          <div className="col-lg">
            <hr />
            <h4 className={styles['experience__h4']}>Areas of Expertise</h4>
            <hr />
          </div>
        </div>
        <ul>
          <div>Scrum</div>
          <ul>
            <li>
              {' '}
              <a href="https://www.scrum.org/certificates/550532">
                Professional Scrum Master I (PSM I) certification
              </a>
            </li>
            <li>
              Preparation, moderation and follow-ups of Scrum Events (Daily,
              Retro, Refinement, Review, Planning)
            </li>
            <li>
              {' '}
              <a href="https://www.amazon.com/Scrum-Doing-Twice-Work-Half-ebook/dp/B00JI54HCU">
                "The Art of Doing Twice the Work in Half the Time"
              </a>
            </li>
          </ul>
          <div>
            Primary: Conception & Development of Websites and Web Applications
          </div>
          <ul>
            <li>
              Frontend: RedwoodJS, React, Next.js, TypeScript, Tailwind CSS,
              Angular, jQuery, Semantic UI, Bootstrap, Apollo Client
            </li>
            <li>
              Backend: Nest.js, Node, Hasura, Strapi, Ruby on Rails, MySQL,
              PostgreSQL, GraphQL, TypeORM, Prisma
            </li>
            <li>
              Vercel and Heroku for fast rapid deployment, Digital Ocean VPS
              (cheapest way to build full stack apps){' '}
            </li>
            <li>Stripe for Payment </li>
            <li>
              SEO, Online Advertising, Growth Hacking (Adwords, Facebook Ads,
              Product Hunt)
            </li>
            <li>UI and UX design with Sketch and Affinity Photo</li>
            <li>Business Process Modeling & Engineering (BPMN)</li>
            <li>
              Enterprise Resource Planning (ERP) and Business Intelligence (BI)
            </li>
          </ul>
          <div>Secondary: Native iOS App Development</div>
          <ul>
            <li>Swift, Obj-C</li>
          </ul>
          <div>Tertiary: General experience</div>
          <ul>
            <li>Python, Django, PHP, C++</li>
          </ul>
        </ul>

        {/* // Creations */}

        <div className="row">
          <div className="col-lg">
            <hr />
            <h4 className={styles['experience__h4']}>Creations</h4>
            <hr />
          </div>
        </div>
        <ul>
          <ul>
            <li>
              <a href="https://www.psychofit.eu" target="_blank">
                Psychofit
              </a>{' '}
              - Our vision is a world in which mental health is proactively
              promoted and crises are avoided through early intervention. We
              strive to create a strong awareness of mental wellbeing and make
              preventative psychological support a matter of course (Web)
            </li>
            <li>
              <a href="https://ArtifAIry.ai" target="_blank">
                ArtifAIry.ai
              </a>{' '}
              - The Best of AI Art (Web)
            </li>
            <li>
              <a href="https://www.EpicArtBy.AI" target="_blank">
                EpicArtBy.AI
              </a>{' '}
              - Like Pepsi to Cola or Burger King to McDonald's, it's The Best
              of AI Art to ArtifAIry.ai (Web)
            </li>
            <li>
              <a href="https://www.24startups24months.com" target="_blank">
                24Startups24Months.com
              </a>{' '}
              - The 24 Startups in 24 Months Challenge (Web)
            </li>
            <li>
              <a href="https://www.soundchannels.io" target="_blank">
                SoundChannels.io
              </a>{' '}
              - SoundCloud Channels & Services (Web)
            </li>
            <li>
              <a href="https://anchor.fm/ma-ron" target="_blank">
                MaRon
              </a>{' '}
              - A german podcast talking honestly about random topics like
              technology, cold showers, diet, fitness, philosophy, spirituality
              or whatever we have in mind (Podcast)
            </li>
            <li>
              <a href="https://www.remotejobforme.com" target="_blank">
                RemoteJobForMe.com
              </a>{' '}
              - The easiest way to be contacted for a new Remote Job (Web)
            </li>
            <li>
              <a href="https://www.remotelane.com/" target="_blank">
                Remotelane.com
              </a>{' '}
              - A simple Remote Job Board (Web){' '}
              <a
                href="https://www.producthunt.com/posts/remotelane-3-0"
                target="_blank"
              >
                🏆 #4 Product of the Day on Product Hunt | January 01, 2021 🏆
              </a>{' '}
            </li>

            <li>
              <a href="https://www.web3remotejob.com/" target="_blank">
                Web3RemoteJob.com
              </a>{' '}
              - One of the most popular Web3 Remote Job Sites with Blockchain,
              Smart Contracts and Crypto Jobs (Web)
            </li>
            <li>
              <a href="https://www.nftremotejob.com/" target="_blank">
                NFTRemoteJob.com
              </a>{' '}
              - The most popular NFT Remote Job Site with Remote Jobs related to
              NFT (Non-fungible tokens) (Web)
            </li>
            <li>
              <a href="https://www.remotejob.ph/" target="_blank">
                Remotejob.ph
              </a>{' '}
              - Platform for connecting Filipino workers with remote/online jobs
              (Web)
            </li>
            <li>
              <a href="https://www.remotejob.global/" target="_blank">
                Remotejob.global
              </a>{' '}
              - Remote jobs from all around the web (Web)
            </li>
            <li>
              <a href="https://www.ronatory.com/" target="_blank">
                ronatory.com
              </a>{' '}
              - Personal Website (Web)
            </li>
          </ul>
          <div>Inactive</div>
          <ul>
            <li>
              <a href="https://www.jobsfromhome.global/" target="_blank">
                Jobsfromhome.global
              </a>{' '}
              - Remote jobs from all around the web. (Web)
            </li>
            <li>
              <a href="https://www.workremotely.global/" target="_blank">
                Workremotely.global
              </a>{' '}
              - Remote jobs from all around the web. (Web)
            </li>
            <li>
              <a href="https://www.fiveminutejournalapp.com/" target="_blank">
                FiveMinuteJournalApp.com
              </a>{' '}
              - Five minute journaling every day (Web)
            </li>
            <li>
              <a href="https://epicahead.com/" target="_blank">
                EpicAhead.com
              </a>{' '}
              - Blog about all upcoming projects (Web)
            </li>
            <li>
              <a
                href="https://apps.apple.com/us/app/ai-tagger/id1166265776"
                target="_blank"
              >
                AI Tagger
              </a>{' '}
              - Generate tags from photos using AI (iOS)
            </li>
            <li>
              <a
                href="https://apps.apple.com/us/app/stay-focused-every-second-counts/id1228857801"
                target="_blank"
              >
                Stay Focused
              </a>{' '}
              - Focus App to overcome distraction (iOS)
            </li>
            <li>
              <a
                href="https://apps.apple.com/us/app/influencer-stack/id1161261383"
                target="_blank"
              >
                Influencer Stack
              </a>{' '}
              - Share links with click count inside app (iOS)
            </li>
            <li>
              <a
                href="https://apps.apple.com/us/app/leadit/id1031084522"
                target="_blank"
              >
                LeadIt
              </a>{' '}
              - Share photos with vote count inside app (iOS)
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=ckJwHiMqhsE"
                target="_blank"
              >
                Friends and Enemies
              </a>{' '}
              - One tap game (iOS)
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=9UReKo3g4-o"
                target="_blank"
              >
                Jump and Score
              </a>{' '}
              - One tap game (iOS)
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=RRhZImR0PCc"
                target="_blank"
              >
                Blue Future Ball
              </a>{' '}
              - One tap game (iOS)
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=pMB4Qj09Z4g"
                target="_blank"
              >
                Green Cage Ball
              </a>{' '}
              - One tap game (iOS)
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=wkJ5hy76OoY"
                target="_blank"
              >
                Djump
              </a>{' '}
              - One tap game (iOS)
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=CyULeN97hGQ"
                target="_blank"
              >
                Liney
              </a>{' '}
              - One tap game (iOS)
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=1uYGso1ipeo"
                target="_blank"
              >
                Drapped
              </a>{' '}
              - One tap game (iOS)
            </li>
            <li>
              <a href="#">One Billion Dollar App</a> - One Billion Dollar
              Version of The Million Dollar Homepage (iOS)
            </li>
            <li>
              <a href="#">SkyOkay.com</a> - Cheap Flight Alerts (Web)
            </li>
            <li>
              <a href="#">LinkedTo.xyz</a> - Link Submission (Web)
            </li>
            <li>
              <a href="https://ronatory.wordpress.com/" target="_blank">
                ronatory.wordpress.com
              </a>{' '}
              - Personal Website (Web)
            </li>
          </ul>
        </ul>

        {/* // Working Experience */}

        <div className="row">
          <div className="col-lg">
            <hr />
            <h4 className={styles['experience__h4']}>Working Experience</h4>
            <hr />
          </div>
        </div>

        <ul>
          <div>
            <span className={styles['experience__text_secondary']}>2014:</span>{' '}
            Internship / Working Student / Web Dev at <i>bitExpert AG</i>
          </div>
          <ul>
            <li>
              Development of an{' '}
              <a href="https://www.bitexpert.de/renewal/" target="_blank">
                Automation Tool
              </a>{' '}
              to create offers (B2B, PHP, ExtJs)
            </li>
            <li>
              <small>
                Remaining projects are under Non-disclosure agreement
              </small>
            </li>
          </ul>
          <div>
            <span className={styles['experience__text_secondary']}>2015:</span>{' '}
            Working Student / Quality Assurance / SAP Migration at{' '}
            <i>MVV Energie AG</i> and <i>Roche Diagnostics GmbH</i>
          </div>
          <ul>
            <li>
              Run Test Cases and migrate specific data to ensure the migration
              to a new system
            </li>
            <li>
              <small>
                Remaining projects are under Non-disclosure agreement
              </small>
            </li>
          </ul>
          <div>
            <span className={styles['experience__text_secondary']}>
              2015-2016:
            </span>{' '}
            Web Dev at <i>The Amaze App (acquired by Zalando SE)</i>
          </div>
          <ul>
            <li>
              Development of Blogger Portal where Fashion Influencers upload
              their outfits to showcase them via native Apps (Web, AngularJS)
            </li>
          </ul>
          <div>
            <span className={styles['experience__text_secondary']}>
              2016 - 2021:
            </span>{' '}
            iOS Developer at{' '}
            <i>grandcentrix GmbH (acquired by Vodafone GmbH)</i>
          </div>
          <ul>
            <li>
              Development{' '}
              <a
                href="https://apps.apple.com/de/app/migros-einkaufen-sparen/id412845300"
                target="_blank"
              >
                Migros App
              </a>{' '}
              - Largest supermarket chain in Switzerland (iOS, Swift, Obj-C)
            </li>
            <li>
              Development{' '}
              <a
                href="https://apps.apple.com/de/app/enet-smart-home/id1156443356"
                target="_blank"
              >
                eNet Smart Home App
              </a>{' '}
              (iOS, Swift)
            </li>
            <li>
              Development{' '}
              <a
                href="https://apps.apple.com/de/app/clever-config/id1382747822"
                target="_blank"
              >
                Clever Config App
              </a>{' '}
              (iOS, Swift)
            </li>
            <li>Lead Developer for unreleased trade show App (iOS, Swift)</li>
            <li>
              Lead Developer for debug App to support the development of an
              embedded product (iOS, Swift)
            </li>
            <li>
              <small>
                Remaining projects are under Non-disclosure agreement
              </small>
            </li>
          </ul>
          <div>
            <span className={styles['experience__text_secondary']}>
              2020 - 2021:
            </span>{' '}
            Scrum Master at <i>grandcentrix GmbH (acquired by Vodafone GmbH)</i>
          </div>
          <ul>
            <li>
              Scrum Master Role{' '}
              <a
                href="https://apps.apple.com/de/app/migros-einkaufen-sparen/id412845300"
                target="_blank"
              >
                Migros App
              </a>{' '}
              - Largest supermarket chain in Switzerland (iOS, Swift, Obj-C)
            </li>
            <li>
              <small>
                Remaining projects are under Non-disclosure agreement
              </small>
            </li>
          </ul>
          <div>
            <span className={styles['experience__text_secondary']}>
              2023 - 2023:
            </span>{' '}
            Mobile Development Lecturer at <i>Syntax GmbH</i>
          </div>
          <ul>
            <li>Focus on iOS Development </li>
          </ul>
          <div>
            <b>
              <span className={styles['experience__text_secondary']}>
                2014 - Present:
              </span>{' '}
              FREElance iOS Developer / Full-Stack Web Developer
            </b>
          </div>
          <ul>
            <li>Own Creations</li>
            <li>
              <small>
                Remaining projects are under Non-disclosure agreement
              </small>
            </li>
          </ul>
          <div>
            <b>
              <span className={styles['experience__text_secondary']}>
                2023 - Present:
              </span>{' '}
              Co-Founder at{' '}
              <i>
                <a href="https://psychofit.eu" target="_blank">
                  Psychofit
                </a>
              </i>
            </b>
          </div>
          <ul>
            <li>
              As Co-Founder at Psychofit, I have taken on multifaceted roles to
              drive the company's growth and development. My contributions
              include the creation and management of our website, leveraging my
              technical and creative skills to ensure a seamless online
              presence. Additionally, I play a pivotal role in the recruitment
              process, conducting interviews with potential partners to build a
              competent and dynamic team. My involvement extends to strategic
              planning, where I actively participate in decision-making
              processes, shaping the company's direction and validating new
              ideas to propel Psychofit towards its objectives.
            </li>
          </ul>
        </ul>

        {/* // Education */}

        <div className="row">
          <div className="col-lg">
            <hr />
            <h4 className={styles['experience__h4']}>Education</h4>
            <hr />
          </div>
        </div>
        <ul>
          <div>
            <span className={styles['experience__text_secondary']}>
              1995-1999:
            </span>{' '}
            Elementary School
          </div>
          <div>
            <span className={styles['experience__text_secondary']}>
              1999-2005:
            </span>{' '}
            High School
          </div>
          <div>
            <span className={styles['experience__text_secondary']}>
              2005-2007:
            </span>{' '}
            Vocational School - Specializing in Business and Administration
          </div>
          <div>
            <span className={styles['experience__text_secondary']}>
              2008-2011:
            </span>{' '}
            Vocational School - Apprenticeship Retail Management Assistant,
            Grade 1.6 <small>(best: 1.0, worst: 5.0)</small>
          </div>
          <div>
            <span className={styles['experience__text_secondary']}>
              2011-2016:
            </span>{' '}
            University of Applied Sciences: Business / Enterprise Computing
            (B.Sc.), Grade 1.9 <small>(best: 1.0, worst: 5.0)</small>
          </div>
        </ul>
      </div>
    </div>
  </div>
);
